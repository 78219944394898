/* tslint:disable:forin */
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { environment } from '../../environments/environment';

import { User } from '../../models/user';
import {map} from 'rxjs/operators';


@Injectable()
export class AuthService {

	private currentUserSubject: BehaviorSubject<User>;
	currentUser: Observable<User>;

	public constructor(
		public http: HttpClient
	) {
		this.currentUserSubject = new BehaviorSubject<User>(JSON.parse( localStorage.getItem('currentUser') ));
		this.currentUser = this.currentUserSubject.asObservable();
	}

	public isLoggedIn() {
		return localStorage.getItem('token') !== null;
	}

	public getCurrentUser(): User {
		return JSON.parse( localStorage.getItem('currentUser') );
		// return this.currentUserSubject.value;
	}

	public setCurrentUser(user: User) {
		// this.currentUserSubject.next( user );
		localStorage.setItem('currentUser', JSON.stringify( user ) );
	}

	public getUserToken() {
		return localStorage.getItem('token');
	}

	public login( email: string, password: string ) {
		const formData = new URLSearchParams();
		formData.set('grant_type', 'password');
		formData.set('scope', environment.scope);
		formData.set('client_id', environment.clientId);
		formData.set('client_secret', environment.clientSecret);
		formData.set('username', email);
		formData.set('password', password);

		const headers = new HttpHeaders({
			 'Content-Type': 'application/x-www-form-urlencoded'
		});

		return this.http
			.post( `${environment.authURL}connect/token`, formData.toString(), {headers})
			.pipe( map( response => {

				// @ts-ignore
				if ( response.access_token ) {
					// @ts-ignore
					localStorage.setItem('token', response.access_token );

					return response;
				}
			}) );
	}


	public logout() {
		this.currentUser = null;
		this.currentUserSubject = null;
		localStorage.removeItem('token');
		localStorage.removeItem('currentUser');
		localStorage.removeItem('remember');
		// localStorage.removeItem('playerId');
	}

	public setNotificationRead() {
		const user = this.getCurrentUser();
		user.notificheLette = 'S';
		this.setCurrentUser( user );
	}
}
