import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { RouteReuseStrategy } from '@angular/router';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpLoaderFactory } from '../providers/utility/http-loader-factory';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { OneSignal } from '@ionic-native/onesignal/ngx';
import { FileTransfer } from '@ionic-native/file-transfer/ngx';
import { File } from '@ionic-native/file/ngx';
import { FileOpener } from '@ionic-native/file-opener/ngx';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { Loader, Messager } from '../providers/utility';
import { ErrorInterceptor } from '../providers/interceptor/error.interceptor';
import { JwtInterceptor } from '../providers/interceptor/jwt.interceptor';
import { LoaderInterceptor } from '../providers/interceptor/loader.interceptor';

import { AuthService } from '../providers/services/auth';
import { AuthGuard } from '../providers/services/auth-guard';
import { UserService } from '../providers/services/user';
import { SeminarService } from '../providers/services/seminar';
import { ExamService } from '../providers/services/exam';
import { DocumentService } from '../providers/services/document';
import { SharedModule } from "./shared/shared.module";




@NgModule({
    declarations: [AppComponent],
    entryComponents: [],
    providers: [
        OneSignal,
        FileTransfer,
        FileOpener,
        File,
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        // Services
        AuthGuard,
        AuthService,
        UserService,
        SeminarService,
        ExamService,
        DocumentService,
        Loader,
        Messager,
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true }
    ],
    bootstrap: [AppComponent],
    imports: [
        BrowserModule,
        HttpClientModule,
        IonicModule.forRoot(),
        AppRoutingModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        SharedModule
    ]
})
export class AppModule {}


