import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { Seminar } from '../../models/seminar';

@Injectable({
	providedIn: 'root'
})
export class SeminarService {

	constructor(
		private http: HttpClient
	) { }

	getList() {
		return this.http
			.get<Seminar>(`${environment.apiURL}Seminars`)
			.pipe( map( response => {
				// @ts-ignore
				return ( response.success === true ? response.payload : { error: true, message: response.errors[0].message } );
			}) );
	}


	getDetail( seminarOid: string ) {
		return this.http
			.get<Seminar>(`${environment.apiURL}Seminars/GetSeminar?seminarId=${seminarOid}`)
			.pipe( map( response => {
				// @ts-ignore
				return ( response.success === true ? response.payload : { error: true, message: response.errors[0].message } );
			}) );
	}

	applySeminar( seminarId ) {
		return this.http
			.put(`${environment.apiURL}Seminars?seminarOid=${seminarId}`, {})
			.pipe( map( response => {
				// @ts-ignore
				return ( response.success === true ? response.payload : { error: true, message: response.errors[0].message } );
			}) );
	}

}
