import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { Loader } from '../utility/loader';
import { environment } from '../../environments/environment';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {

	activeRequests = 0;

	/**
	 * URLs for which the loading screen should not be enabled
	 */
	skipUrls = [
		'connect/token',
		'Students',
		'/auth/refresh',
	];

	constructor(
		private loader: Loader
	) {
	}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

		let displayLoader = true;

		for ( const skipUrl of this.skipUrls ) {
			if ( new RegExp( environment.authURL + skipUrl).test(request.url) ||
				new RegExp( environment.apiURL + skipUrl).test(request.url) ) {
				displayLoader = false;
				break;
			}
		}

		if ( displayLoader ) {
			if (this.activeRequests === 0) {
				this.loader.showLoader();
			}
			this.activeRequests++;

			return next.handle(request).pipe(
				finalize(() => {
					this.activeRequests--;
					if ( this.activeRequests === 0 ) {
						this.loader.hideLoader();
					}
				})
			);
		} else {
			return next.handle(request);
		}
	}
}
