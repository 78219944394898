import { Injectable } from '@angular/core';
import { ToastController, AlertController } from '@ionic/angular';


@Injectable()
export class Messager {

	constructor(
		private toastCtrl: ToastController,
		private alertCtrl: AlertController
	) { }

	public async showError(text: string) {
		const toast = await this.toastCtrl.create({
			message: text,
			duration: 5000,
			position: 'top',
			color: 'danger',
			cssClass: 'error'
		});
		toast.present();
	}

	public async showSuccess(text: string) {
		const toast = await this.toastCtrl.create({
			message: text,
			duration: 5000,
			position: 'top',
			color: 'success',
			cssClass: 'success'
		});
		toast.present();
	}

	public async showMessage(
		header: string,
		message: string,
		// tslint:disable-next-line:ban-types
		callback: Function
	) {
		if ( !callback ) {
			callback = () => {};
		}

		const alert = await this.alertCtrl.create({
			header,
			message,
			buttons: [{
				text: 'OK',
				handler: () => {
					callback();
				}
			}]
		});
		alert.present();
	}
}
