import { Injectable } from '@angular/core';
import { User } from '../../models/user';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { environment } from '../../environments/environment';

@Injectable({
	providedIn: 'root'
})
export class UserService {

	constructor(
		private http: HttpClient
	) { }

	getDetail() {
		return this.http
			.get<User>(`${environment.apiURL}Students`)
			.pipe( map( response => {
				// @ts-ignore
				return ( response.success === true ? response.payload : { error: true, message: response.errors[0].message } );
			}) );
	}

	edit( user: User ) {
		return this.http
			.put(`${environment.apiURL}Students`, user )
			.pipe( map( response => {
				// @ts-ignore
				return ( response.success === true ? response.payload : { error: true, message: response.errors[0].message } );
			}) );
	}


	setPlayerId( playerId: string ) {
		return this.http
			.put(`${environment.apiURL}Students/SetPlayerId?playerId=${playerId}`, {} )
			.pipe( map( response => {
				// @ts-ignore
				return ( response.success === true ? response.payload : { error: true, message: response.errors[0].message } );
			}) );
	}


	updatePassword( oldPassword: string, newPassword: string ) {
		return this.http
			.post(`${environment.apiURL}User/ChangePassword?oldPassword=${oldPassword}&newPassword=${newPassword}`, { } )
			.pipe( map( response => {
				// @ts-ignore
				return ( response.success === true ? response.payload : { error: true, message: response.errors[0].message } );
			}) );
	}

}
