import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { Exam } from '../../models/exam';

@Injectable({
	providedIn: 'root'
})
export class ExamService {

	constructor(
		private http: HttpClient
	) { }

	getList() {
		return this.http
			.get<Exam>(`${environment.apiURL}Enrollments`)
			.pipe( map( response => {
				// @ts-ignore
				return ( response.success === true ? response.payload : { error: true, message: response.errors[0].message } );
			}) );
	}

	applyExam( examId ) {
		const data = {
			situazioneEsami: [{
				corsoOid: examId
			}]
		};

		return this.http
			.put(`${environment.apiURL}Enrollments`, data)
			.pipe( map( response => {
				// @ts-ignore
				return ( response.success === true ? response.payload : { error: true, message: response.errors[0].message } );
			}) );
	}

}
