import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

import { FileTransfer, FileTransferObject } from '@ionic-native/file-transfer/ngx';
import { File } from '@ionic-native/file/ngx';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { Messager } from '../utility/messager';
import { Loader } from '../utility/loader';

@Injectable({
	providedIn: 'root'
})
export class DocumentService {

	fileTransfer: FileTransferObject;
	ext: string;
	mimeType: string;

	constructor(
		private http: HttpClient,
		private transfer: FileTransfer,
		private file: File,
		private fileOpener: FileOpener,
		private loader: Loader,
		private messager: Messager
	) {
	}

	private _getFilename(filename: string) {
		return filename.replace(/[^a-z0-9]/gi, '_').toLowerCase();
	}

	private _getFileExt(mimeType: string) {
		switch (mimeType) {
			case 'application/pdf':
				return 'pdf';
				break;
			case 'application/msword':
				return 'doc';
				break;
			case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
				return 'docx';
				break;
			case 'application/vnd.ms-powerpoint':
				return 'ppt';
				break;
			case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
				return 'pptx';
				break;
		}
	}

	getDocument(oId: string, fileName: string) {
		this.loader.showLoader();

		if (oId) {
			const url = `${environment.apiURL}Documents/GetDocument?id=${oId}`;
			if (window['cordova'] != null) {
				this.fileTransfer = this.transfer.create();
				this.fileTransfer
					.download(url, this.file.dataDirectory + this._getFilename(fileName) + '.pdf')
					.then((entry) => {

						this.fileOpener
							.showOpenWithDialog(entry.nativeURL, 'application/pdf')
							.then(() => {
								this.loader.hideLoader();
								console.log('File is opened');
							})
							.catch(e => {
								this.loader.hideLoader();
								console.log('Error opening file', e);
								this.messager.showError('Errore nell\'apertura dell file');
							});

					}, (error) => {
						this.loader.hideLoader();
						this.messager.showError('Impossibile scaricare il file');
					});
			} else {
				window.open(url, '_blank');
			}
		}

	}

}

