<div class="notification-card">
	<p>
		<strong class="pink">{{ 'attention' | translate }}</strong>
		<br>
		<strong>{{ 'payment_1' | translate }}</strong><br>
		{{ 'payment_2' | translate }}
	</p>
</div>

<div class="cta-container">
	<ion-button (click)="closePaymentAlert()" shape="round">{{ 'payment_3' | translate }}</ion-button>
</div>
