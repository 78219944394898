import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class Loader {

	private loading: any;
	private loadingLabel: string;

	constructor(
		private translateService: TranslateService,
		private loadingCtrl: LoadingController
	) { }

	private getLabel() {

		let currLang = this.translateService.currentLang;
		if ( !currLang ) {
			currLang = this.translateService.defaultLang;
		}

		switch ( currLang ) {
			case 'en':
				return 'Loading...';
			case 'it':
				return 'Caricamento...';
		}
	}

	public async showLoader() {
		if ( !this.loading ) {
			this.loading = await this.loadingCtrl.create({
				message: this.getLabel()
			});
			await this.loading.present();
		}

		setTimeout( () => {
			if ( this.loading ) {
				this.hideLoader();
			}
			this.hideLoader();
		}, 1000 );

		return false;
	}

	public async hideLoader() {
		if ( this.loading ) {
			this.loading.dismiss();
			await this.loading.dismiss();
			this.loading = null;
		}
		return false;
	}

}
