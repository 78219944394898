import { Component, OnInit } from '@angular/core';
import {ModalController} from '@ionic/angular';

@Component({
  selector: 'app-payment-alert',
  templateUrl: './payment-alert.component.html',
  styleUrls: ['./payment-alert.component.scss'],
})
export class PaymentAlertComponent implements OnInit {

	constructor(
		private modalController: ModalController
	) { }

	ngOnInit() {}

	closePaymentAlert() {
		this.modalController.dismiss({
			dismissed: true
		});
	}

}
