import { Component, HostListener } from '@angular/core';
import { StatusBar, Style } from "@capacitor/status-bar"


import { environment } from '../environments/environment';
import { Platform } from '@ionic/angular';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { AuthService } from '../providers/services/auth';
import { SplashScreen } from '@capacitor/splash-screen';
import { App } from '@capacitor/app';
import OneSignal from 'onesignal-cordova-plugin';

@Component({
	selector: 'app-root',
	templateUrl: 'app.component.html',
	styleUrls: ['app.component.scss']
})
export class AppComponent {

	public osVersion: string;

	constructor(
		public router: Router,
		private platform: Platform,
		translate: TranslateService,
		private authService: AuthService,
	) {
		translate.setDefaultLang('it');

		// @ts-ignore
		if (window.cordova) {
			StatusBar.setStyle({
				style: Style.Light
			});

			SplashScreen.hide();
		}

		this.initializeApp();
	}

	initializeApp() {
		// @ts-ignore
		if (window.cordova) {

			OneSignal.initialize(environment.oneSignalAppId);

			let myClickListener = async function (event) {
				if (this.authService.isLoggedIn()) {
					this.router.navigate(['/student/material']);
				} else {
					sessionStorage.setItem('redirect_to', '/student/material');
				}
			};

			OneSignal.Notifications.addEventListener("click", myClickListener);

			OneSignal.User.pushSubscription.optIn();

			OneSignal.Notifications.requestPermission(true).then((accepted: boolean) => {
				console.log("User accepted notifications: " + accepted);
				console.log("User subscription id: " + OneSignal.User.pushSubscription.id);
				localStorage.setItem('playerId', OneSignal.User.pushSubscription.id);
			});
		}

		App.addListener('backButton', () => {
			if (this.router.url === '/student/home') {
				App.exitApp();
			}

			if (this.router.url === '/student/exam' ||
				this.router.url === '/student/material' ||
				this.router.url === '/student/thesis' ||
				this.router.url === '/student/profile' ||
				//this.router.url === '/student/profile-abroad' ||
				this.router.url === '/student/password' ||
				this.router.url === '/student/notification' ||
				this.router.url === '/student/seminar') {
				this.router.navigate(['/student/home']);
			}

			window.history.back();
		});

	}

}
