import {Component, Input, OnInit} from '@angular/core';

@Component({
	selector: 'app-pink-title',
	templateUrl: './pink-title.component.html',
	styleUrls: ['./pink-title.component.scss'],
})
export class PinkTitleComponent implements OnInit {

	@Input() icon: string;
	@Input() label: string;
	@Input() customClass: string;

	constructor() { }

	ngOnInit() {
	}

}
