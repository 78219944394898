import { IonicModule } from '@ionic/angular';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { CustomHeaderComponent } from './custom-header/custom-header.component';
import { PinkTitleComponent } from './pink-title/pink-title.component';
import { PaymentAlertComponent } from './payment-alert/payment-alert.component';

import { AuthService } from '../../providers/services/auth';


@NgModule({
	declarations: [
		CustomHeaderComponent,
		PinkTitleComponent,
		PaymentAlertComponent
	],
	exports: [
		CustomHeaderComponent,
		PinkTitleComponent,
		PaymentAlertComponent
	],
	imports: [
		IonicModule,
		CommonModule,
		RouterModule,
		TranslateModule,
	],
	providers: [
		AuthService
	]
})
export class SharedModule { }

