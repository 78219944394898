<ion-header *ngIf="userData">
	<ion-toolbar>
		<ion-buttons style="height: 100%;" slot="start" [hidden]="mobile">
			<ion-menu-button><img src="../../../assets/img/elisa-tab-negative.png"></ion-menu-button>
		</ion-buttons>
		<ion-buttons slot="start" [hidden]="!mobile">
			<ion-button [routerLink]="'/student/profile'">
				<ion-label>
					{{ userData.nome | uppercase }}<br>
					{{ userData.cognome | uppercase }}
				</ion-label>
			</ion-button>
		</ion-buttons>
		<ion-buttons slot="end" class="ion-padding" [hidden]="!mobile">
			<ion-button slot="end" [routerLink]="'/student/notification'" class="notification-btn"
				[ngClass]="{ 'active': userData.notificheLette != 'S' }">
				<i class="icon-notification"></i>
			</ion-button>
		</ion-buttons>
	</ion-toolbar>
</ion-header>
<ion-menu contentId="main-content" *ngIf="userData">
	<ion-header>
		<ion-toolbar>

			<ion-buttons slot="start">
				<ion-menu-toggle>
					<ion-button [routerLink]="'/student/profile'">
						<ion-label>
							{{ userData.nome | uppercase }}<br>
							{{ userData.cognome | uppercase }}
						</ion-label>
					</ion-button>
				</ion-menu-toggle>
			</ion-buttons>
			<ion-buttons slot="end" class="ion-padding">
				<ion-menu-toggle slot="end" [routerLink]="'/student/notification'" class="notification-btn"
					[ngClass]="{ 'active': userData.notificheLette != 'S' }">
					<i class="icon-notification"></i>
				</ion-menu-toggle>
			</ion-buttons>
		</ion-toolbar>
	</ion-header>
	<ion-content>
		<ion-menu-toggle>
			<ion-item></ion-item>
			<ion-item [routerLink]="'/student/home'">
				<ion-label>
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -3 50 50">
						<path
							d="M 24.962891 1.0546875 A 1.0001 1.0001 0 0 0 24.384766 1.2636719 L 1.3847656 19.210938 A 1.0005659 1.0005659 0 0 0 2.6152344 20.789062 L 4 19.708984 L 4 46 A 1.0001 1.0001 0 0 0 5 47 L 18.832031 47 A 1.0001 1.0001 0 0 0 19.158203 47 L 30.832031 47 A 1.0001 1.0001 0 0 0 31.158203 47 L 45 47 A 1.0001 1.0001 0 0 0 46 46 L 46 19.708984 L 47.384766 20.789062 A 1.0005657 1.0005657 0 1 0 48.615234 19.210938 L 41 13.269531 L 41 6 L 35 6 L 35 8.5859375 L 25.615234 1.2636719 A 1.0001 1.0001 0 0 0 24.962891 1.0546875 z M 25 3.3222656 L 44 18.148438 L 44 45 L 32 45 L 32 26 L 18 26 L 18 45 L 6 45 L 6 18.148438 L 25 3.3222656 z M 37 8 L 39 8 L 39 11.708984 L 37 10.146484 L 37 8 z M 20 28 L 30 28 L 30 45 L 20 45 L 20 28 z">
						</path>
					</svg>
					{{ 'home.home' | translate }}
				</ion-label>
			</ion-item>
			<ion-item [routerLink]="'/student/exam'">
				<ion-label>
					<i class="icon-exam"></i>
					{{ 'home.exams' | translate }}
				</ion-label>
			</ion-item>
			<ion-item [routerLink]="'/student/material'">
				<ion-label>
					<i class="icon-material"></i>
					{{ 'home.materials' | translate }}
				</ion-label>
			</ion-item>
			<ion-item [routerLink]="'/student/thesis'">
				<ion-label>
					<i class="icon-thesis"></i>
					{{ 'home.thesis' | translate }}
				</ion-label>
			</ion-item>
			<ion-item [routerLink]="'/student/seminar'" routerLinkActive #rla="routerLinkActive"
				[class.destroy]="rla.isActive">
				<ion-label>
					<i class="icon-seminar"></i>
					{{ 'home.seminars' | translate }}
				</ion-label>
			</ion-item>
			<ion-item [routerLink]="'/student/profile/'">
				<ion-label>
					<i class="icon-profile"></i>
					{{ 'home.profile' | translate }}
				</ion-label>
			</ion-item>
		</ion-menu-toggle>
	</ion-content>
</ion-menu>