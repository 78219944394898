import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
// import { NavController } from '@ionic/angular';
import { AuthService } from '../services/auth';
import { TranslateService } from '@ngx-translate/core';
import { Messager } from '../utility/messager';
import { environment } from '../../environments/environment';


@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

	loginError: string;
	genericError: string;

	constructor(
		private authService: AuthService,
		private translateService: TranslateService,
		private messager: Messager,
		private router: Router
	) {
		this.translateService
			.get('errors')
			.subscribe(
				value => {
					this.loginError   = value.loginError;
					this.genericError = value.genericError;
				}
			);
	}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next
					.handle(request)
					.pipe(catchError(err => {

			if ( err.status === 401 || err.status === 403 ) {
				// auto logout if 401 response returned from api
				this.messager.showError( 'Utente non autenticato. Rieffettua il login!' );
				// TODO
				// this.authService.logout();
				this.router.navigateByUrl('/login');
			} else if ( err.status === 422 ) {
				if ( err.error.errors ) {
					this.messager.showError( err.error.errors[Object.keys( err.error.errors )[0]] );
				}
			} else {
				if ( new RegExp( environment.authURL + 'connect/token').test(request.url) ) {
					this.messager.showError(this.loginError);
				} else {
					this.messager.showError(this.genericError);
				}
			}

			const error = err.error.message || err.statusText;
			return throwError(error);
		}));
	}
}
