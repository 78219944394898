import { Component, Input, OnChanges, OnInit, SimpleChanges, HostListener } from '@angular/core';
import { AuthService } from '../../../providers/services/auth';
import { Router } from '@angular/router';


enum ScreenSize {
	Large = 'lg',
	Medium = 'md',
	Small = 'sm'
}

@Component({
	selector: 'app-custom-header',
	templateUrl: './custom-header.component.html',
	styleUrls: ['./custom-header.component.scss'],
})

export class CustomHeaderComponent implements OnInit, OnChanges {
	public screenSize: ScreenSize;
	public userData: any;
	public mobile: boolean = false;

	@HostListener('window:resize', ['$event'])
	onResize(event: Event): void {
		this.checkScreenSize();
	}

	private checkScreenSize(): void {
		const screenWidth = window.innerWidth;
		if (screenWidth >= 1200) {
			this.screenSize = ScreenSize.Large;
		} else if (screenWidth >= 768 && screenWidth < 1200) {
			this.screenSize = ScreenSize.Medium;
		} else {
			this.screenSize = ScreenSize.Small;
		}
	}

	private checkMobile(): void {
		if (window['cordova'] != null) {
			this.mobile = true;
		}
	}


	@Input() update: boolean;

	constructor(
		public authService: AuthService,
		public router: Router
	) {
		this.checkScreenSize();
		this.checkMobile();
	}

	ngOnInit() {
		this.userData = this.authService.getCurrentUser();
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.update) {
			this.userData = this.authService.getCurrentUser();
		}
	}

}
